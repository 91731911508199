import React from "react"
import Image from "gatsby-image"
import Text from "../RevealingText"

const Approach = props => {
  const images = props.images
  //const brandColor = props.color
  const approachPara = props.para

  return (
    <section className="spApproach">
      <div className="spApproach-para">
        <h3 className="paraHeading mb-24 text-green">Approach</h3>

        <p>{approachPara}</p>
      </div>
      <div className="spApproach-images">
        {images.map(image => {
          return (
            <Image
              key={image.approachImages.localFile.childImageSharp.fluid.src}
              fluid={image.approachImages.localFile.childImageSharp.fluid}
              alt={props.name + " Screenshots"}
            />
          )
        })}
      </div>
    </section>
  )
}

export default Approach
