import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/Layout"
import Challenges from "../components/singleProjectPage/Challenges"
import Approach from "../components/singleProjectPage/Approach"
import Result from "../components/singleProjectPage/Result"
import Text from "../components/RevealingText"
import Word from "../components/RevealingWord"
//import back from "../images/background-next.jpg"
import Seo from "../components/Seo"
const ProjectTemplate = ({ data }) => {
  //General Project Data
  const project = data.prismicProjects.data
  const projectName = project.project_title.text
  const brandColor = project.brand_color
  const projectHeading = project.project_main_heading.text
  const projectDeliveries = project.project_deliveries.text
  const projectLink = project.project_web_link.url

  // Project Overview Data
  const overviewPara = project.body[0].items[0].projectOverview.text
  const projectMainImage =
    project.project_main_image.localFile.childImageSharp.fluid

  //Project Challenges Data
  const logo =
    project.body[1].challengePara.clientsLogo.localFile.childImageSharp.fluid
  const challengePara = project.body[1].challengePara.project_challenges.text
  const challengeImages = project.body[1].items

  //Project Approach Data
  const approachPara = project.body[2].approachPara.approach_paragraph.text
  const approachImages = project.body[2].items

  //Project Result Data
  const resultPara = project.body[3].resultsPara.result_paragraph.text
  const resultImages = project.body[3].items
  return (
    <Layout>
      <Seo title={`KUBO | ${projectName}`} isArticle={false} />
      <div className="spHeader">
        <h1 className="pageHeadings text-green">
          <Word word={projectHeading} />
        </h1>
      </div>
      <div className="spOverview">
        <div className="spOverview-title">
          <h3 className="paraHeading mb-24 text-green">Overview</h3>
        </div>
        <div className="spOverview-para ">
          <p className="mb-48">{overviewPara}</p>

          <div className="spOverview-para--deliveries mb-24">
            <h4>Deliveries</h4>
            <p className="">{projectDeliveries}</p>
          </div>
          <a className="pageLinks" href={projectLink}>
            See Live
          </a>
        </div>
      </div>
      <div className="spBannerImage">
        <Image
          className="scale-image"
          fluid={projectMainImage}
          alt={project.project_main_image.alt}
        />
      </div>
      <Challenges
        name={projectName}
        color={brandColor}
        logo={logo}
        para={challengePara}
        images={challengeImages}
      />
      <Approach
        name={projectName}
        color={brandColor}
        para={approachPara}
        images={approachImages}
      />
      <Result
        name={projectName}
        color={brandColor}
        para={resultPara}
        images={resultImages}
      />

      <div className="spNextProject">
        <div className="projectBtn">
          <Link to="/project" className="glitch" data-word="all project">
            All Project
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query MyProjects($slug: String!) {
    prismicProjects(uid: { eq: $slug }) {
      uid
      id
      data {
        project_title {
          text
        }
        project_main_heading {
          text
        }
        brand_color
        project_web_link {
          url
        }
        project_deliveries {
          text
        }
        project_main_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicProjectsBodyOverviewSection {
            id
            items {
              projectOverview: overview_paragraph {
                text
              }
            }
          }
          ... on PrismicProjectsBodyChallengeSection {
            id
            items {
              challengeImages: challenges_images {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            challengePara: primary {
              project_challenges {
                text
              }
              clientsLogo: project_logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectsBodyApproachSection {
            id
            approachPara: primary {
              approach_paragraph {
                text
              }
            }
            items {
              approachImages: approach_images {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectsBodyProjectResult {
            id
            resultsPara: primary {
              result_paragraph {
                text
              }
            }
            items {
              resultsImages: result_images {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
