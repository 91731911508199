import React from "react"
import Image from "gatsby-image"
import Text from "../RevealingText"

const Challenges = props => {
  const images = props.images
  const brandColor = props.color
  const brandLogo = props.logo
  const ChallengePara = props.para

  return (
    <section className="spChallenges">
      <div className="spChallenges-para">
        <h3 className="paraHeading mb-24 text-green">Challenge</h3>
        <p className="articleCard-overview">{ChallengePara}</p>
      </div>
      <div className="spChallenges-images" style={{ background: brandColor }}>
        <div className="spChallenges-images--logo">
          <Image
            className="scale-image"
            fluid={brandLogo}
            alt={props.name + " Logo"}
          />
        </div>
        <div className="spChallenges-images--image">
          {images.map((image, index) => {
            return (
              <Image
                loading="eager"
                key={image.challengeImages.localFile.childImageSharp.fluid.src}
                fluid={image.challengeImages.localFile.childImageSharp.fluid}
                alt={props.name + " Screenshots"}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Challenges
