import React from "react"
import Image from "gatsby-image"
import Text from "../RevealingText"

const Result = props => {
  const mobileImages = props.images
  const brandColor = props.color
  const resultPara = props.para

  return (
    <section className="spResult">
      <div className="spResult-content">
        <div className="spResult-content--title">
          <h3 className="paraHeading mb-24 text-green">Results</h3>
        </div>
        <div className="spResult-content--para">
          <p>{resultPara}</p>
        </div>
      </div>
      <div className="spResult-images" style={{ background: brandColor }}>
        {mobileImages.map(image => {
          return (
            <Image
              imgStyle={{
                objectPosition: "top center",
              }}
              key={image.resultsImages.localFile.childImageSharp.fluid.src}
              fluid={image.resultsImages.localFile.childImageSharp.fluid}
              alt={props.name + " Screenshots"}
            />
          )
        })}
      </div>
    </section>
  )
}

export default Result
